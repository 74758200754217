import React, { useState, useEffect } from "react"
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Error from "../../component/Error/Error";
import { apiCall } from "../../helpers/api";


const VerifyEmail = () => {
  
  const { otp } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [errors, setErrors] = useState({});
  

  const verifyEmail = () => {
    const params = {
      path: `verify-email`,
      method: "POST",
      data: {
        otp,
      },
    };

    dispatch(apiCall(params))
      .then((response) => {
        if (response.success){
          toast.success(response.message);
          history.replace('/login');
        } else {
          toast.error(response.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  
  useEffect(() => {
    verifyEmail();
  }, []);

  return(
    <p>Verifying...</p>
  );
};

export default VerifyEmail;